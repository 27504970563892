import React from "react";

const AlertCircleSolid = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 0C5.383 0 0 5.383 0 12c0 6.618 5.383 12 12 12s12-5.382 12-12c0-6.617-5.383-12-12-12zm-1.2 4.8h2.4v8.4h-2.4V4.8zM12 18.3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
  </svg>
);

export default AlertCircleSolid;
