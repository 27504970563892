import React from "react";

const Email = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 11.23l10.758-6.333a2.464 2.464 0 0 0-1.158-.296H2.4c-.422 0-.814.113-1.158.296L12 11.23z" />
    <path d="M12.632 13.536a1.261 1.261 0 0 1-1.264.002L.004 6.847c0 .01-.004.02-.004.029v10.247c0 1.254 1.076 2.276 2.4 2.276h19.2c1.324 0 2.4-1.022 2.4-2.276V6.876c0-.01-.004-.02-.004-.03l-11.364 6.69z" />
  </svg>
);

export default Email;
