import React from "react";

const BackArrow = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12.5 5.5a8.955 8.955 0 0 0-6.144 2.442L4.207 5.793A.999.999 0 0 0 2.5 6.5v11a1 1 0 0 0 1 1h11a1.002 1.002 0 0 0 .707-1.707l-3.51-3.509A3.991 3.991 0 0 1 15.5 10.5a4.005 4.005 0 0 1 4 3.998v.002a1 1 0 1 0 2 0v-.005c-.002-4.96-4.039-8.995-9-8.995z" />
  </svg>
);

export default BackArrow;
