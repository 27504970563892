// import React from 'react'
// import { css } from '@emotion/core'
// import Centered from '../Centered'

// const primaryHeaderAnnouncementStyles = css`
//   background-color: #fafafa;
//   border-bottom: 1px solid #eee;

//   p {
//     margin: 0;
//     padding: 1em 0;
//     color: $dark-blue;
//     font-size: 0.7em;
//   }
// `

function PrimaryHeaderAnnouncement() {
  return null

  // return (
  //   <div css={primaryHeaderAnnouncementStyles}>
  //     <Centered>
  //       <p>....</p>
  //     </Centered>
  //   </div>
  // )
}

export default PrimaryHeaderAnnouncement
