import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import classnames from 'classnames'
import { chain } from 'lodash'
import * as Icons from 'glob:./icons/*.js'
import theme from 'shared/styles/theme'

const iconStyles = css`
  fill: currentColor;
  vertical-align: middle;
  line-height: 1em;
`

const Icon = ({ className, icon, color, size }) => {
  const IconComp =
    Icons[
      chain(icon)
        .camelCase()
        .upperFirst()
        .value()
    ]

  const props = {
    className: classnames('icon', icon, className),
    style: { width: `${size}em`, height: `${size}em` },
  }

  if (color) props.style.fill = theme[color] || 'currentColor'
  return IconComp ? <IconComp css={iconStyles} {...props} /> : null
}

Icon.defaultProps = {
  size: 1,
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
}

export default Icon
