import React from "react";

const Cog = props => (
  <svg viewBox="0 0 268.765 268.765" {...props}>
    <path
      d="M267.92 119.461c-.425-3.778-4.83-6.617-8.639-6.617-12.315 0-23.243-7.231-27.826-18.414-4.682-11.454-1.663-24.812 7.515-33.231a7.463 7.463 0 0 0 .817-10.133 132.977 132.977 0 0 0-21.289-21.5c-3.063-2.458-7.557-2.116-10.213.825-8.01 8.871-22.398 12.168-33.516 7.529-11.57-4.867-18.866-16.591-18.152-29.176a7.455 7.455 0 0 0-6.595-7.849 134.163 134.163 0 0 0-30.232-.08 7.478 7.478 0 0 0-6.654 7.689c.438 12.461-6.946 23.98-18.401 28.672-10.985 4.487-25.272 1.218-33.266-7.574-2.642-2.896-7.063-3.252-10.141-.853a133.478 133.478 0 0 0-21.74 21.493c-2.481 3.086-2.116 7.559.802 10.214 9.353 8.47 12.373 21.944 7.514 33.53-4.639 11.046-16.109 18.165-29.24 18.165-4.261-.137-7.296 2.723-7.762 6.597a134.618 134.618 0 0 0-.058 30.561c.422 3.794 4.961 6.608 8.812 6.608 11.702-.299 22.937 6.946 27.65 18.415 4.698 11.454 1.678 24.804-7.514 33.23a7.469 7.469 0 0 0-.817 10.126 133.459 133.459 0 0 0 21.259 21.508c3.079 2.481 7.559 2.131 10.228-.81 8.04-8.893 22.427-12.184 33.501-7.536 11.599 4.852 18.895 16.575 18.181 29.167a7.464 7.464 0 0 0 6.595 7.85 133.572 133.572 0 0 0 30.233.081 7.479 7.479 0 0 0 6.653-7.696c-.451-12.454 6.946-23.973 18.386-28.657 11.059-4.517 25.286-1.211 33.281 7.572a7.51 7.51 0 0 0 10.142.848 133.787 133.787 0 0 0 21.74-21.494 7.461 7.461 0 0 0-.803-10.213c-9.353-8.47-12.388-21.946-7.529-33.524 4.568-10.899 15.612-18.217 27.491-18.217l1.662.043c3.853.313 7.398-2.655 7.865-6.588a134.504 134.504 0 0 0 .06-30.561zm-133.325 60.03c-24.718 0-44.824-20.106-44.824-44.824 0-24.717 20.106-44.824 44.824-44.824 24.717 0 44.823 20.107 44.823 44.824 0 24.718-20.106 44.824-44.823 44.824z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default Cog;
