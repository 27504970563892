import React from "react";

const Drag = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 .006h24v24H0z" />
    <path d="M11.293 8.708l1.414-1.414L8 2.587 3.293 7.294l1.414 1.414L7 6.415v11.586h2V6.415zM19.293 15.294L17 17.587V6.001h-2v11.586l-2.293-2.293-1.414 1.414L16 21.415l4.707-4.707z" />
  </svg>
);

export default Drag;
