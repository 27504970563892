/* global process */

const globalConfig = {
  production: {
    sampleTestId: 197,
    api: {
      protocol: 'https',
      host: 'api.questionable.io'
    },
    static: {
      protocol: 'https',
      host: 'static.questionable.io'
    },
    functions: {
      protocol: 'https',
      host: 'us-central1-questionabledotio.cloudfunctions.net/api'
    },
    app: {
      protocol: 'https',
      host: 'app.questionable.io'
    },
    website: {
      protocol: 'https',
      host: 'questionable.io'
    }
  },
  development: {
    sampleTestId: 81,
    api: {
      protocol: 'http',
      host: 'localhost:3000'
    },
    static: {
      protocol: 'https',
      host: 'static.questionable.io'
    },
    functions: {
      protocol: 'http',
      host: 'localhost:5000/questionabledotio/us-central1/api'
    },
    app: {
      protocol: 'http',
      host: 'localhost:3010'
    },
    website: {
      protocol: 'http',
      host: 'localhost:8000'
    }
  }
}

export default globalConfig[process.env.NODE_ENV]
