import sharedTheme from './theme.scss'
export default sharedTheme

/**
 * Named Export (Utilities)
 */

export const toRGB = function(h) {
  if (!h) return ''
  let r = 0
  let g = 0
  let b = 0

  if (h.length === 4) {
    r = '0x' + h[1] + h[1]
    g = '0x' + h[2] + h[2]
    b = '0x' + h[3] + h[3]
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2]
    g = '0x' + h[3] + h[4]
    b = '0x' + h[5] + h[6]
  }

  return `${+r},${+g},${+b}`
}

function hexToSix(hex) {
  if (hex.length === 6) return hex
  if (hex.length !== 3) {
    throw new Error('hex must be 3 chars')
  }
  return `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
}

export const lighten = function(hex, amt) {
  const usePound = hex[0] === '#'
  hex = hexToSix(usePound ? hex.slice(1) : hex)
  const num = parseInt(hex, 16)

  let r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export const darken = (hex, amt) => lighten(hex, amt * -1)
