import React from 'react'
import Icon from 'shared/ui/Icon'

const QuestionableEmail = ({ email }) => (
  <a className="icon-text" href={`mailto:${email}`}>
    <Icon icon="email" /><span>{email}</span>
  </a>
)

export default QuestionableEmail
