import React from "react";

const VDots = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <circle cx={12} cy={5.001} r={2} />
    <circle cx={12} cy={12.001} r={2} />
    <circle cx={12} cy={19.001} r={2} />
  </svg>
);

export default VDots;
