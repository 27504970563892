import React from "react";

const InformationCircleSolid = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 0C5.383 0 0 5.385 0 12c0 6.619 5.383 12.001 12 12.001S24 18.619 24 12c0-6.615-5.383-12-12-12zm0 5.701a1.5 1.5 0 1 1-.002 3.002A1.5 1.5 0 0 1 12 5.7zm3.6 12.3H8.4v-2.4h2.4V12H9.6V9.6H12a1.2 1.2 0 0 1 1.2 1.2v4.801h2.4v2.4z" />
  </svg>
);

export default InformationCircleSolid;
