import React from "react";

const Clock = props => (
  <svg viewBox="0 0 443.596 443.596" {...props}>
    <path d="M221.799 0C99.5 0 0 99.498 0 221.798s99.5 221.798 221.799 221.798c122.301 0 221.797-99.498 221.797-221.798S344.1 0 221.799 0zm0 381.123c-87.854 0-159.323-71.473-159.323-159.325 0-87.852 71.469-159.325 159.323-159.325 87.852 0 159.328 71.473 159.328 159.325 0 87.852-71.477 159.325-159.328 159.325z" />
    <path d="M332.505 205.832h-94.738v-94.739a8.46 8.46 0 0 0-8.46-8.459h-25.378c-4.672 0-8.462 3.787-8.462 8.459v128.576c0 4.672 3.79 8.459 8.462 8.459h128.576a8.458 8.458 0 0 0 8.458-8.459v-25.378a8.458 8.458 0 0 0-8.458-8.459z" />
  </svg>
);

export default Clock;
