import React from 'react'
import { useMediaLayout } from 'use-media'
import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button'
import Link from 'gatsby-link'
import { Columns, Column } from 'react-flex-columns'
import { css } from '@emotion/core'
import globalConfig from '../shared/config/common/globalConfig'
import Centered from '../Centered'
import Logo from '../shared/ui/Logo'
import theme from '../styles/theme'
import '@reach/menu-button/styles.css'

const primaryHeaderStyles = css`
  padding: 1em 0;

  a {
    /* Negate dotted underline */
    border: none;
  }
`
const navWideStyles = css`
  a {
    font-weight: 400;
    font-size: 0.8em;
    display: inline-block;

    &:not(.signup) {
      transition: all 0.2s;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: #637a8a;
      display: inline-block;
      padding: 0.5em 1em;

      &:hover {
        transform: translateY(0.2em);
      }
    }
    &.signup {
      margin-left: ${theme.gutter};
      box-shadow: 0 0 10px -8px #000;
      text-transform: uppercase;
    }
  }
`
const menuButtonStyles = css`
  box-shadow: 0 0 10px -8px #000;
`
const menuStyles = css`
  padding: 0.5em 0;
  border: none;
  background-color: ${theme.blue};
  border-radius: 0.5em;
  overflow: hidden;
  transform: translateY(0.5em);

  a {
    padding: 0.5em 1em;
    color: #fff;
    font-size: 1.2rem;
    border: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: ${theme.darkBlue};
    }
  }
`

function PrimaryHeader() {
  const isWide = useMediaLayout({ minWidth: '1100px' })

  return (
    <div className="primary-header">
      <Centered as="header" css={primaryHeaderStyles}>
        <Columns split middle>
          <Column>
            <Link to="/">
              <Logo theme="current" />
            </Link>
          </Column>
          <Column>
            <nav>
              {isWide ? (
                <div css={navWideStyles}>
                  <Link to="/our-difference">Our Difference</Link>
                  <Link to="/feature-tour">Feature Tour</Link>
                  <Link to="/pricing">Plans & Pricing</Link>
                  <a href={`${globalConfig.app.protocol}://${globalConfig.app.host}/auth/login`}>Login</a>
                  <Link to="/signup" className="action-button signup">
                    Signup
                  </Link>
                </div>
              ) : (
                <Menu>
                  <MenuButton css={menuButtonStyles} className="button small action-button">
                    Menu
                  </MenuButton>
                  <MenuList css={menuStyles}>
                    <MenuLink as={Link} to="/our-difference">
                      Our Difference
                    </MenuLink>
                    <MenuLink as={Link} to="/feature-tour">
                      Feature Tour
                    </MenuLink>
                    <MenuLink as={Link} to="/pricing">
                      Pricing
                    </MenuLink>
                    <MenuLink href={`${globalConfig.app.protocol}://${globalConfig.app.host}/auth/login`}>
                      Login
                    </MenuLink>
                    <MenuLink as={Link} to="/signup">
                      Signup
                    </MenuLink>
                  </MenuList>
                </Menu>
              )}
            </nav>
          </Column>
        </Columns>
      </Centered>
    </div>
  )
}

export default PrimaryHeader
