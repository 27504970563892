import React from 'react'
import Link from 'gatsby-link'
import { css } from '@emotion/core'
import { useMediaLayout } from 'use-media'
import { Columns, Column } from 'react-flex-columns'
import Centered from '../Centered'
import Icon from '../shared/ui/Icon'
import EmailLink from '../shared/ui/EmailLink'
import theme from '../styles/theme'
import { toRGB } from '../shared/styles/theme'

const primaryFooterStyles = css`
  color: #fff;
  background-color: ${theme.darkDarkBlue};
  font-size: 0.6em;
  color: rgba(${toRGB(theme.brightBlue)}, 0.5);
  padding: 2em 0;

  a {
    border: none;
  }
`

function PrimaryFooter() {
  const stack = useMediaLayout({ maxWidth: '600px' })

  return (
    <footer css={primaryFooterStyles} className="primary-footer">
      <Centered className="spacing">
        <Columns split middle stack={stack}>
          <Column>
            <a href="https://twitter.com/questionableio">
              <Icon icon="twitter" size={2} />
            </a>
          </Column>
          <Column>
            <div className="link-group">
              <span>&copy; Questionable.io 2019</span>
              <span>
                Contact: <EmailLink email="hello@questionable.io" />
              </span>
              <Link to="/legal/terms-of-service">Terms of Service</Link>
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </div>
          </Column>
        </Columns>
      </Centered>
    </footer>
  )
}

export default PrimaryFooter
