import React from 'react'
import classnames from 'classnames'
import Helmet from 'react-helmet'
import PrimaryHeaderAnnouncement from './ui/PrimaryHeaderAnnouncement'
import PrimaryHeader from './ui/PrimaryHeader'
import PrimaryFooter from './ui/PrimaryFooter'

// Global Styles
import './shared/styles/globalStyles.scss'
import './styles/websiteGlobalStyles.scss'

// Will be overridden by CSS, which prevents the FOUC effect
const embeddedStyles = `
  svg { display: none; }
  `

const DocumentLayout = ({ children, className }) => (
  <div className={classnames('website', className)}>
    <Helmet>
      <title>Send coding assessments for programmers with Questionable.io</title>
      <meta name="description" content="Create coding tests for assessing coder's skills." />

      <meta property="og:title" content="Questionable.io. Create Tests to Test Coders" />
      <meta property="og:description" content="Create coding tests for assessing coder's skills." />
      <meta property="og:image" content="https://static.questionable.io/images/social/test-questions-1000.png" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://questionable.io" />
      <meta property="og:site_name" content="Questionable.io" />

      <link rel="icon" type="image/png" href="https://static.questionable.io/images/favicon.png" />
      {/* Vendor CSS */}
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/3.0.3/normalize.css"></link>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Baloo+Chettan|Lato:400,700|Courgette" />
      {/* Prevent FOUT */}
      <style>{embeddedStyles}</style>
    </Helmet>
    <div className="primary-content">
      <PrimaryHeaderAnnouncement />
      <PrimaryHeader />
      <main>{children}</main>
    </div>
    <PrimaryFooter />
  </div>
)

export default DocumentLayout
