import React from 'react'
import PropTypes from 'prop-types'

const themes = {
  current: ['currentColor'],
  'white-on-blue': ['#FFFFFF', '#3887C0'],
  white: ['#FFFFFF'],
  'dark-blue': ['#205e8e'],
  blue: ['#2B92DA'],
  'light-gray': ['#d4dee4'],
}

const Logo = ({ theme = 'white-on-blue', size = 14 }) => {
  const [top, bottom] = themes[theme]
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, 392, 58" style={{ width: `${size}rem` }}>
      {bottom && (
        <g fill={bottom}>
          <path d="M2.227,28.76 Q2.227,24.08 3.697,20.48 Q5.167,16.88 7.717,14.45 Q10.267,12.02 13.717,10.76 Q17.167,9.5 21.187,9.5 Q25.207,9.5 28.657,10.76 Q32.107,12.02 34.687,14.45 Q37.267,16.88 38.737,20.48 Q40.207,24.08 40.207,28.76 Q40.207,34.64 38.047,38.75 Q35.887,42.86 32.167,45.2 Q33.067,45.62 34.297,46.1 Q35.527,46.58 36.877,47.06 Q38.227,47.54 39.667,47.99 Q41.107,48.44 42.367,48.8 Q42.427,49.16 42.427,49.43 Q42.427,49.7 42.427,49.94 Q42.427,53.36 40.657,54.92 Q38.887,56.48 36.007,56.48 Q33.247,56.48 30.307,54.95 Q27.367,53.42 24.307,50.54 L21.667,48.08 L21.187,48.08 Q17.107,48.08 13.627,46.79 Q10.147,45.5 7.627,43.04 Q5.107,40.58 3.667,36.98 Q2.227,33.38 2.227,28.76 z M13.027,28.76 Q13.027,34.22 15.247,36.92 Q17.467,39.62 21.187,39.62 Q24.967,39.62 27.187,36.92 Q29.407,34.22 29.407,28.76 Q29.407,23.36 27.217,20.66 Q25.027,17.96 21.247,17.96 Q17.527,17.96 15.277,20.63 Q13.027,23.3 13.027,28.76 z" />
          <path d="M45.547,18.26 Q46.147,18.08 47.437,17.9 Q48.727,17.72 50.107,17.72 Q51.427,17.72 52.477,17.9 Q53.527,18.08 54.247,18.62 Q54.967,19.16 55.357,20.09 Q55.747,21.02 55.747,22.52 L55.747,35.42 Q55.747,37.88 56.947,38.93 Q58.147,39.98 60.427,39.98 Q61.807,39.98 62.677,39.74 Q63.547,39.5 64.027,39.26 L64.027,18.26 Q64.627,18.08 65.917,17.9 Q67.207,17.72 68.587,17.72 Q69.907,17.72 70.957,17.9 Q72.007,18.08 72.727,18.62 Q73.447,19.16 73.837,20.09 Q74.227,21.02 74.227,22.52 L74.227,40.76 Q74.227,43.76 71.707,45.26 Q69.607,46.58 66.697,47.27 Q63.787,47.96 60.367,47.96 Q57.127,47.96 54.397,47.24 Q51.667,46.52 49.687,45.02 Q47.707,43.52 46.627,41.15 Q45.547,38.78 45.547,35.42 z" />
          <path d="M95.887,47.96 Q92.347,47.96 89.317,46.97 Q86.287,45.98 84.037,44 Q81.787,42.02 80.497,39.02 Q79.207,36.02 79.207,32 Q79.207,28.04 80.497,25.19 Q81.787,22.34 83.887,20.51 Q85.987,18.68 88.687,17.81 Q91.387,16.94 94.207,16.94 Q97.387,16.94 99.997,17.9 Q102.607,18.86 104.497,20.54 Q106.387,22.22 107.437,24.56 Q108.487,26.9 108.487,29.66 Q108.487,31.7 107.347,32.78 Q106.207,33.86 104.167,34.16 L89.347,36.38 Q90.007,38.36 92.047,39.35 Q94.087,40.34 96.727,40.34 Q99.187,40.34 101.377,39.71 Q103.567,39.08 104.947,38.24 Q105.907,38.84 106.567,39.92 Q107.227,41 107.227,42.2 Q107.227,44.9 104.707,46.22 Q102.787,47.24 100.387,47.6 Q97.987,47.96 95.887,47.96 z M94.207,24.38 Q92.767,24.38 91.717,24.86 Q90.667,25.34 90.007,26.09 Q89.347,26.84 89.017,27.77 Q88.687,28.7 88.627,29.66 L98.887,27.98 Q98.707,26.78 97.567,25.58 Q96.427,24.38 94.207,24.38 z" />
          <path d="M138.007,38.3 Q138.007,42.86 134.587,45.44 Q131.167,48.02 124.507,48.02 Q121.987,48.02 119.827,47.66 Q117.667,47.3 116.137,46.55 Q114.607,45.8 113.737,44.66 Q112.867,43.52 112.867,41.96 Q112.867,40.52 113.467,39.53 Q114.067,38.54 114.907,37.88 Q116.647,38.84 118.897,39.59 Q121.147,40.34 124.087,40.34 Q125.947,40.34 126.937,39.8 Q127.927,39.26 127.927,38.36 Q127.927,37.52 127.207,37.04 Q126.487,36.56 124.807,36.26 L123.007,35.9 Q117.787,34.88 115.237,32.69 Q112.687,30.5 112.687,26.42 Q112.687,24.2 113.647,22.4 Q114.607,20.6 116.347,19.4 Q118.087,18.2 120.517,17.54 Q122.947,16.88 125.887,16.88 Q128.107,16.88 130.057,17.21 Q132.007,17.54 133.447,18.2 Q134.887,18.86 135.727,19.91 Q136.567,20.96 136.567,22.4 Q136.567,23.78 136.057,24.77 Q135.547,25.76 134.767,26.42 Q134.287,26.12 133.327,25.79 Q132.367,25.46 131.227,25.19 Q130.087,24.92 128.917,24.74 Q127.747,24.56 126.787,24.56 Q124.807,24.56 123.727,25.01 Q122.647,25.46 122.647,26.42 Q122.647,27.08 123.247,27.5 Q123.847,27.92 125.527,28.28 L127.387,28.7 Q133.147,30.02 135.577,32.33 Q138.007,34.64 138.007,38.3 z" />
          <path d="M153.127,36.98 Q153.127,38.54 154.117,39.2 Q155.107,39.86 156.907,39.86 Q157.807,39.86 158.767,39.71 Q159.727,39.56 160.447,39.32 Q160.987,39.98 161.377,40.79 Q161.767,41.6 161.767,42.74 Q161.767,45.02 160.057,46.46 Q158.347,47.9 154.027,47.9 Q148.747,47.9 145.897,45.5 Q143.047,43.1 143.047,37.7 L143.047,12.02 Q143.707,11.84 144.937,11.63 Q146.167,11.42 147.547,11.42 Q150.187,11.42 151.657,12.35 Q153.127,13.28 153.127,16.28 L153.127,20.12 L160.987,20.12 Q161.347,20.78 161.677,21.77 Q162.007,22.76 162.007,23.96 Q162.007,26.06 161.077,26.99 Q160.147,27.92 158.587,27.92 L153.127,27.92 z" />
          <path d="M166.687,9.74 Q166.687,7.46 168.217,5.9 Q169.747,4.34 172.267,4.34 Q174.787,4.34 176.317,5.9 Q177.847,7.46 177.847,9.74 Q177.847,12.02 176.317,13.58 Q174.787,15.14 172.267,15.14 Q169.747,15.14 168.217,13.58 Q166.687,12.02 166.687,9.74 z M177.367,46.94 Q176.707,47.06 175.417,47.27 Q174.127,47.48 172.807,47.48 Q171.487,47.48 170.437,47.3 Q169.387,47.12 168.667,46.58 Q167.947,46.04 167.557,45.11 Q167.167,44.18 167.167,42.68 L167.167,18.26 Q167.827,18.14 169.117,17.93 Q170.407,17.72 171.727,17.72 Q173.047,17.72 174.097,17.9 Q175.147,18.08 175.867,18.62 Q176.587,19.16 176.977,20.09 Q177.367,21.02 177.367,22.52 z" />
          <path d="M214.027,32.42 Q214.027,36.14 212.887,39.05 Q211.747,41.96 209.677,43.94 Q207.607,45.92 204.727,46.94 Q201.847,47.96 198.307,47.96 Q194.767,47.96 191.887,46.88 Q189.007,45.8 186.937,43.79 Q184.867,41.78 183.727,38.9 Q182.587,36.02 182.587,32.42 Q182.587,28.88 183.727,26 Q184.867,23.12 186.937,21.11 Q189.007,19.1 191.887,18.02 Q194.767,16.94 198.307,16.94 Q201.847,16.94 204.727,18.05 Q207.607,19.16 209.677,21.17 Q211.747,23.18 212.887,26.06 Q214.027,28.94 214.027,32.42 z M193.027,32.42 Q193.027,36.08 194.437,38.03 Q195.847,39.98 198.367,39.98 Q200.887,39.98 202.237,38 Q203.587,36.02 203.587,32.42 Q203.587,28.82 202.207,26.87 Q200.827,24.92 198.307,24.92 Q195.787,24.92 194.407,26.87 Q193.027,28.82 193.027,32.42 z" />
          <path d="M237.487,28.52 Q237.487,26.66 236.437,25.79 Q235.387,24.92 233.587,24.92 Q232.387,24.92 231.217,25.22 Q230.047,25.52 229.207,26.12 L229.207,46.94 Q228.607,47.12 227.317,47.3 Q226.027,47.48 224.647,47.48 Q223.327,47.48 222.277,47.3 Q221.227,47.12 220.507,46.58 Q219.787,46.04 219.397,45.11 Q219.007,44.18 219.007,42.68 L219.007,24.68 Q219.007,23.06 219.697,22.04 Q220.387,21.02 221.587,20.18 Q223.627,18.74 226.717,17.84 Q229.807,16.94 233.587,16.94 Q240.367,16.94 244.027,19.91 Q247.687,22.88 247.687,28.16 L247.687,46.94 Q247.087,47.12 245.797,47.3 Q244.507,47.48 243.127,47.48 Q241.807,47.48 240.757,47.3 Q239.707,47.12 238.987,46.58 Q238.267,46.04 237.877,45.11 Q237.487,44.18 237.487,42.68 z" />
          <path d="M266.467,40.52 Q267.487,40.52 268.717,40.31 Q269.947,40.1 270.547,39.74 L270.547,34.94 L266.227,35.3 Q264.547,35.42 263.467,36.02 Q262.387,36.62 262.387,37.82 Q262.387,39.02 263.317,39.77 Q264.247,40.52 266.467,40.52 z M265.987,16.94 Q269.227,16.94 271.897,17.6 Q274.567,18.26 276.457,19.61 Q278.347,20.96 279.367,23.03 Q280.387,25.1 280.387,27.92 L280.387,41.36 Q280.387,42.92 279.517,43.91 Q278.647,44.9 277.447,45.62 Q273.547,47.96 266.467,47.96 Q263.287,47.96 260.737,47.36 Q258.187,46.76 256.357,45.56 Q254.527,44.36 253.537,42.5 Q252.547,40.64 252.547,38.18 Q252.547,34.04 255.007,31.82 Q257.467,29.6 262.627,29.06 L270.487,28.22 L270.487,27.8 Q270.487,26.06 268.957,25.31 Q267.427,24.56 264.547,24.56 Q262.267,24.56 260.107,25.04 Q257.947,25.52 256.207,26.24 Q255.427,25.7 254.887,24.59 Q254.347,23.48 254.347,22.28 Q254.347,20.72 255.097,19.79 Q255.847,18.86 257.407,18.2 Q259.147,17.54 261.517,17.24 Q263.887,16.94 265.987,16.94 z" />
          <path d="M286.747,7.94 Q287.347,7.76 288.667,7.55 Q289.987,7.34 291.367,7.34 Q292.687,7.34 293.737,7.52 Q294.787,7.7 295.507,8.24 Q296.227,8.78 296.587,9.71 Q296.947,10.64 296.947,12.14 L296.947,18.02 Q298.447,17.42 299.797,17.18 Q301.147,16.94 302.767,16.94 Q305.647,16.94 308.227,17.96 Q310.807,18.98 312.787,20.93 Q314.767,22.88 315.907,25.76 Q317.047,28.64 317.047,32.42 Q317.047,36.32 315.877,39.23 Q314.707,42.14 312.607,44.06 Q310.507,45.98 307.477,46.97 Q304.447,47.96 300.787,47.96 Q296.827,47.96 294.067,47.03 Q291.307,46.1 289.387,44.72 Q286.747,42.86 286.747,39.92 z M300.787,39.92 Q303.547,39.92 305.077,38.03 Q306.607,36.14 306.607,32.42 Q306.607,28.64 305.047,26.78 Q303.487,24.92 300.847,24.92 Q299.647,24.92 298.777,25.16 Q297.907,25.4 296.887,25.88 L296.887,38.96 Q297.547,39.38 298.477,39.65 Q299.407,39.92 300.787,39.92 z" />
          <path d="M332.407,46.94 Q331.747,47.06 330.457,47.27 Q329.167,47.48 327.847,47.48 Q326.527,47.48 325.477,47.3 Q324.427,47.12 323.707,46.58 Q322.987,46.04 322.597,45.11 Q322.207,44.18 322.207,42.68 L322.207,7.88 Q322.867,7.76 324.157,7.55 Q325.447,7.34 326.767,7.34 Q328.087,7.34 329.137,7.52 Q330.187,7.7 330.907,8.24 Q331.627,8.78 332.017,9.71 Q332.407,10.64 332.407,12.14 z" />
          <path d="M354.247,47.96 Q350.707,47.96 347.677,46.97 Q344.647,45.98 342.397,44 Q340.147,42.02 338.857,39.02 Q337.567,36.02 337.567,32 Q337.567,28.04 338.857,25.19 Q340.147,22.34 342.247,20.51 Q344.347,18.68 347.047,17.81 Q349.747,16.94 352.567,16.94 Q355.747,16.94 358.357,17.9 Q360.967,18.86 362.857,20.54 Q364.747,22.22 365.797,24.56 Q366.847,26.9 366.847,29.66 Q366.847,31.7 365.707,32.78 Q364.567,33.86 362.527,34.16 L347.707,36.38 Q348.367,38.36 350.407,39.35 Q352.447,40.34 355.087,40.34 Q357.547,40.34 359.737,39.71 Q361.927,39.08 363.307,38.24 Q364.267,38.84 364.927,39.92 Q365.587,41 365.587,42.2 Q365.587,44.9 363.067,46.22 Q361.147,47.24 358.747,47.6 Q356.347,47.96 354.247,47.96 z M352.567,24.38 Q351.127,24.38 350.077,24.86 Q349.027,25.34 348.367,26.09 Q347.707,26.84 347.377,27.77 Q347.047,28.7 346.987,29.66 L357.247,27.98 Q357.067,26.78 355.927,25.58 Q354.787,24.38 352.567,24.38 z" />
          <path d="M369.109,45.299 Q369.109,44.417 369.655,43.829 Q370.201,43.241 371.146,43.241 Q372.091,43.241 372.637,43.829 Q373.183,44.417 373.183,45.299 Q373.183,46.181 372.637,46.769 Q372.091,47.357 371.146,47.357 Q370.201,47.357 369.655,46.769 Q369.109,46.181 369.109,45.299 z" />
          <path d="M374.653,33.959 Q374.653,33.161 375.188,32.615 Q375.724,32.069 376.606,32.069 Q377.488,32.069 378.023,32.615 Q378.559,33.161 378.559,33.959 Q378.559,34.757 378.023,35.303 Q377.488,35.849 376.606,35.849 Q375.724,35.849 375.188,35.303 Q374.653,34.757 374.653,33.959 z M378.391,46.979 Q378.16,47.021 377.708,47.095 Q377.257,47.168 376.795,47.168 Q376.333,47.168 375.965,47.105 Q375.598,47.042 375.346,46.853 Q375.094,46.664 374.957,46.339 Q374.821,46.013 374.821,45.488 L374.821,36.941 Q375.052,36.899 375.503,36.826 Q375.955,36.752 376.417,36.752 Q376.879,36.752 377.246,36.815 Q377.614,36.878 377.866,37.067 Q378.118,37.256 378.254,37.582 Q378.391,37.907 378.391,38.432 z" />
          <path d="M391.222,41.897 Q391.222,43.199 390.823,44.217 Q390.424,45.236 389.7,45.929 Q388.975,46.622 387.967,46.979 Q386.959,47.336 385.72,47.336 Q384.481,47.336 383.473,46.958 Q382.465,46.58 381.741,45.876 Q381.016,45.173 380.617,44.165 Q380.218,43.157 380.218,41.897 Q380.218,40.658 380.617,39.65 Q381.016,38.642 381.741,37.938 Q382.465,37.235 383.473,36.857 Q384.481,36.479 385.72,36.479 Q386.959,36.479 387.967,36.868 Q388.975,37.256 389.7,37.959 Q390.424,38.663 390.823,39.671 Q391.222,40.679 391.222,41.897 z M383.872,41.897 Q383.872,43.178 384.366,43.861 Q384.859,44.543 385.741,44.543 Q386.623,44.543 387.096,43.85 Q387.568,43.157 387.568,41.897 Q387.568,40.637 387.085,39.954 Q386.602,39.272 385.72,39.272 Q384.838,39.272 384.355,39.954 Q383.872,40.637 383.872,41.897 z" />
        </g>
      )}
      <g fill={top}>
        <path d="M1.227,25.76 Q1.227,21.08 2.697,17.48 Q4.167,13.88 6.717,11.45 Q9.267,9.02 12.717,7.76 Q16.167,6.5 20.187,6.5 Q24.207,6.5 27.657,7.76 Q31.107,9.02 33.687,11.45 Q36.267,13.88 37.737,17.48 Q39.207,21.08 39.207,25.76 Q39.207,31.64 37.047,35.75 Q34.887,39.86 31.167,42.2 Q32.067,42.62 33.297,43.1 Q34.527,43.58 35.877,44.06 Q37.227,44.54 38.667,44.99 Q40.107,45.44 41.367,45.8 Q41.427,46.16 41.427,46.43 Q41.427,46.7 41.427,46.94 Q41.427,50.36 39.657,51.92 Q37.887,53.48 35.007,53.48 Q32.247,53.48 29.307,51.95 Q26.367,50.42 23.307,47.54 L20.667,45.08 L20.187,45.08 Q16.107,45.08 12.627,43.79 Q9.147,42.5 6.627,40.04 Q4.107,37.58 2.667,33.98 Q1.227,30.38 1.227,25.76 z M12.027,25.76 Q12.027,31.22 14.247,33.92 Q16.467,36.62 20.187,36.62 Q23.967,36.62 26.187,33.92 Q28.407,31.22 28.407,25.76 Q28.407,20.36 26.217,17.66 Q24.027,14.96 20.247,14.96 Q16.527,14.96 14.277,17.63 Q12.027,20.3 12.027,25.76 z" />
        <path d="M44.547,15.26 Q45.147,15.08 46.437,14.9 Q47.727,14.72 49.107,14.72 Q50.427,14.72 51.477,14.9 Q52.527,15.08 53.247,15.62 Q53.967,16.16 54.357,17.09 Q54.747,18.02 54.747,19.52 L54.747,32.42 Q54.747,34.88 55.947,35.93 Q57.147,36.98 59.427,36.98 Q60.807,36.98 61.677,36.74 Q62.547,36.5 63.027,36.26 L63.027,15.26 Q63.627,15.08 64.917,14.9 Q66.207,14.72 67.587,14.72 Q68.907,14.72 69.957,14.9 Q71.007,15.08 71.727,15.62 Q72.447,16.16 72.837,17.09 Q73.227,18.02 73.227,19.52 L73.227,37.76 Q73.227,40.76 70.707,42.26 Q68.607,43.58 65.697,44.27 Q62.787,44.96 59.367,44.96 Q56.127,44.96 53.397,44.24 Q50.667,43.52 48.687,42.02 Q46.707,40.52 45.627,38.15 Q44.547,35.78 44.547,32.42 z" />
        <path d="M94.887,44.96 Q91.347,44.96 88.317,43.97 Q85.287,42.98 83.037,41 Q80.787,39.02 79.497,36.02 Q78.207,33.02 78.207,29 Q78.207,25.04 79.497,22.19 Q80.787,19.34 82.887,17.51 Q84.987,15.68 87.687,14.81 Q90.387,13.94 93.207,13.94 Q96.387,13.94 98.997,14.9 Q101.607,15.86 103.497,17.54 Q105.387,19.22 106.437,21.56 Q107.487,23.9 107.487,26.66 Q107.487,28.7 106.347,29.78 Q105.207,30.86 103.167,31.16 L88.347,33.38 Q89.007,35.36 91.047,36.35 Q93.087,37.34 95.727,37.34 Q98.187,37.34 100.377,36.71 Q102.567,36.08 103.947,35.24 Q104.907,35.84 105.567,36.92 Q106.227,38 106.227,39.2 Q106.227,41.9 103.707,43.22 Q101.787,44.24 99.387,44.6 Q96.987,44.96 94.887,44.96 z M93.207,21.38 Q91.767,21.38 90.717,21.86 Q89.667,22.34 89.007,23.09 Q88.347,23.84 88.017,24.77 Q87.687,25.7 87.627,26.66 L97.887,24.98 Q97.707,23.78 96.567,22.58 Q95.427,21.38 93.207,21.38 z" />
        <path d="M137.007,35.3 Q137.007,39.86 133.587,42.44 Q130.167,45.02 123.507,45.02 Q120.987,45.02 118.827,44.66 Q116.667,44.3 115.137,43.55 Q113.607,42.8 112.737,41.66 Q111.867,40.52 111.867,38.96 Q111.867,37.52 112.467,36.53 Q113.067,35.54 113.907,34.88 Q115.647,35.84 117.897,36.59 Q120.147,37.34 123.087,37.34 Q124.947,37.34 125.937,36.8 Q126.927,36.26 126.927,35.36 Q126.927,34.52 126.207,34.04 Q125.487,33.56 123.807,33.26 L122.007,32.9 Q116.787,31.88 114.237,29.69 Q111.687,27.5 111.687,23.42 Q111.687,21.2 112.647,19.4 Q113.607,17.6 115.347,16.4 Q117.087,15.2 119.517,14.54 Q121.947,13.88 124.887,13.88 Q127.107,13.88 129.057,14.21 Q131.007,14.54 132.447,15.2 Q133.887,15.86 134.727,16.91 Q135.567,17.96 135.567,19.4 Q135.567,20.78 135.057,21.77 Q134.547,22.76 133.767,23.42 Q133.287,23.12 132.327,22.79 Q131.367,22.46 130.227,22.19 Q129.087,21.92 127.917,21.74 Q126.747,21.56 125.787,21.56 Q123.807,21.56 122.727,22.01 Q121.647,22.46 121.647,23.42 Q121.647,24.08 122.247,24.5 Q122.847,24.92 124.527,25.28 L126.387,25.7 Q132.147,27.02 134.577,29.33 Q137.007,31.64 137.007,35.3 z" />
        <path d="M152.127,33.98 Q152.127,35.54 153.117,36.2 Q154.107,36.86 155.907,36.86 Q156.807,36.86 157.767,36.71 Q158.727,36.56 159.447,36.32 Q159.987,36.98 160.377,37.79 Q160.767,38.6 160.767,39.74 Q160.767,42.02 159.057,43.46 Q157.347,44.9 153.027,44.9 Q147.747,44.9 144.897,42.5 Q142.047,40.1 142.047,34.7 L142.047,9.02 Q142.707,8.84 143.937,8.63 Q145.167,8.42 146.547,8.42 Q149.187,8.42 150.657,9.35 Q152.127,10.28 152.127,13.28 L152.127,17.12 L159.987,17.12 Q160.347,17.78 160.677,18.77 Q161.007,19.76 161.007,20.96 Q161.007,23.06 160.077,23.99 Q159.147,24.92 157.587,24.92 L152.127,24.92 z" />
        <path d="M165.687,6.74 Q165.687,4.46 167.217,2.9 Q168.747,1.34 171.267,1.34 Q173.787,1.34 175.317,2.9 Q176.847,4.46 176.847,6.74 Q176.847,9.02 175.317,10.58 Q173.787,12.14 171.267,12.14 Q168.747,12.14 167.217,10.58 Q165.687,9.02 165.687,6.74 z M176.367,43.94 Q175.707,44.06 174.417,44.27 Q173.127,44.48 171.807,44.48 Q170.487,44.48 169.437,44.3 Q168.387,44.12 167.667,43.58 Q166.947,43.04 166.557,42.11 Q166.167,41.18 166.167,39.68 L166.167,15.26 Q166.827,15.14 168.117,14.93 Q169.407,14.72 170.727,14.72 Q172.047,14.72 173.097,14.9 Q174.147,15.08 174.867,15.62 Q175.587,16.16 175.977,17.09 Q176.367,18.02 176.367,19.52 z" />
        <path d="M213.027,29.42 Q213.027,33.14 211.887,36.05 Q210.747,38.96 208.677,40.94 Q206.607,42.92 203.727,43.94 Q200.847,44.96 197.307,44.96 Q193.767,44.96 190.887,43.88 Q188.007,42.8 185.937,40.79 Q183.867,38.78 182.727,35.9 Q181.587,33.02 181.587,29.42 Q181.587,25.88 182.727,23 Q183.867,20.12 185.937,18.11 Q188.007,16.1 190.887,15.02 Q193.767,13.94 197.307,13.94 Q200.847,13.94 203.727,15.05 Q206.607,16.16 208.677,18.17 Q210.747,20.18 211.887,23.06 Q213.027,25.94 213.027,29.42 z M192.027,29.42 Q192.027,33.08 193.437,35.03 Q194.847,36.98 197.367,36.98 Q199.887,36.98 201.237,35 Q202.587,33.02 202.587,29.42 Q202.587,25.82 201.207,23.87 Q199.827,21.92 197.307,21.92 Q194.787,21.92 193.407,23.87 Q192.027,25.82 192.027,29.42 z" />
        <path d="M236.487,25.52 Q236.487,23.66 235.437,22.79 Q234.387,21.92 232.587,21.92 Q231.387,21.92 230.217,22.22 Q229.047,22.52 228.207,23.12 L228.207,43.94 Q227.607,44.12 226.317,44.3 Q225.027,44.48 223.647,44.48 Q222.327,44.48 221.277,44.3 Q220.227,44.12 219.507,43.58 Q218.787,43.04 218.397,42.11 Q218.007,41.18 218.007,39.68 L218.007,21.68 Q218.007,20.06 218.697,19.04 Q219.387,18.02 220.587,17.18 Q222.627,15.74 225.717,14.84 Q228.807,13.94 232.587,13.94 Q239.367,13.94 243.027,16.91 Q246.687,19.88 246.687,25.16 L246.687,43.94 Q246.087,44.12 244.797,44.3 Q243.507,44.48 242.127,44.48 Q240.807,44.48 239.757,44.3 Q238.707,44.12 237.987,43.58 Q237.267,43.04 236.877,42.11 Q236.487,41.18 236.487,39.68 z" />
        <path d="M265.467,37.52 Q266.487,37.52 267.717,37.31 Q268.947,37.1 269.547,36.74 L269.547,31.94 L265.227,32.3 Q263.547,32.42 262.467,33.02 Q261.387,33.62 261.387,34.82 Q261.387,36.02 262.317,36.77 Q263.247,37.52 265.467,37.52 z M264.987,13.94 Q268.227,13.94 270.897,14.6 Q273.567,15.26 275.457,16.61 Q277.347,17.96 278.367,20.03 Q279.387,22.1 279.387,24.92 L279.387,38.36 Q279.387,39.92 278.517,40.91 Q277.647,41.9 276.447,42.62 Q272.547,44.96 265.467,44.96 Q262.287,44.96 259.737,44.36 Q257.187,43.76 255.357,42.56 Q253.527,41.36 252.537,39.5 Q251.547,37.64 251.547,35.18 Q251.547,31.04 254.007,28.82 Q256.467,26.6 261.627,26.06 L269.487,25.22 L269.487,24.8 Q269.487,23.06 267.957,22.31 Q266.427,21.56 263.547,21.56 Q261.267,21.56 259.107,22.04 Q256.947,22.52 255.207,23.24 Q254.427,22.7 253.887,21.59 Q253.347,20.48 253.347,19.28 Q253.347,17.72 254.097,16.79 Q254.847,15.86 256.407,15.2 Q258.147,14.54 260.517,14.24 Q262.887,13.94 264.987,13.94 z" />
        <path d="M285.747,4.94 Q286.347,4.76 287.667,4.55 Q288.987,4.34 290.367,4.34 Q291.687,4.34 292.737,4.52 Q293.787,4.7 294.507,5.24 Q295.227,5.78 295.587,6.71 Q295.947,7.64 295.947,9.14 L295.947,15.02 Q297.447,14.42 298.797,14.18 Q300.147,13.94 301.767,13.94 Q304.647,13.94 307.227,14.96 Q309.807,15.98 311.787,17.93 Q313.767,19.88 314.907,22.76 Q316.047,25.64 316.047,29.42 Q316.047,33.32 314.877,36.23 Q313.707,39.14 311.607,41.06 Q309.507,42.98 306.477,43.97 Q303.447,44.96 299.787,44.96 Q295.827,44.96 293.067,44.03 Q290.307,43.1 288.387,41.72 Q285.747,39.86 285.747,36.92 z M299.787,36.92 Q302.547,36.92 304.077,35.03 Q305.607,33.14 305.607,29.42 Q305.607,25.64 304.047,23.78 Q302.487,21.92 299.847,21.92 Q298.647,21.92 297.777,22.16 Q296.907,22.4 295.887,22.88 L295.887,35.96 Q296.547,36.38 297.477,36.65 Q298.407,36.92 299.787,36.92 z" />
        <path d="M331.407,43.94 Q330.747,44.06 329.457,44.27 Q328.167,44.48 326.847,44.48 Q325.527,44.48 324.477,44.3 Q323.427,44.12 322.707,43.58 Q321.987,43.04 321.597,42.11 Q321.207,41.18 321.207,39.68 L321.207,4.88 Q321.867,4.76 323.157,4.55 Q324.447,4.34 325.767,4.34 Q327.087,4.34 328.137,4.52 Q329.187,4.7 329.907,5.24 Q330.627,5.78 331.017,6.71 Q331.407,7.64 331.407,9.14 z" />
        <path d="M353.247,44.96 Q349.707,44.96 346.677,43.97 Q343.647,42.98 341.397,41 Q339.147,39.02 337.857,36.02 Q336.567,33.02 336.567,29 Q336.567,25.04 337.857,22.19 Q339.147,19.34 341.247,17.51 Q343.347,15.68 346.047,14.81 Q348.747,13.94 351.567,13.94 Q354.747,13.94 357.357,14.9 Q359.967,15.86 361.857,17.54 Q363.747,19.22 364.797,21.56 Q365.847,23.9 365.847,26.66 Q365.847,28.7 364.707,29.78 Q363.567,30.86 361.527,31.16 L346.707,33.38 Q347.367,35.36 349.407,36.35 Q351.447,37.34 354.087,37.34 Q356.547,37.34 358.737,36.71 Q360.927,36.08 362.307,35.24 Q363.267,35.84 363.927,36.92 Q364.587,38 364.587,39.2 Q364.587,41.9 362.067,43.22 Q360.147,44.24 357.747,44.6 Q355.347,44.96 353.247,44.96 z M351.567,21.38 Q350.127,21.38 349.077,21.86 Q348.027,22.34 347.367,23.09 Q346.707,23.84 346.377,24.77 Q346.047,25.7 345.987,26.66 L356.247,24.98 Q356.067,23.78 354.927,22.58 Q353.787,21.38 351.567,21.38 z" />
        <path d="M368.109,42.299 Q368.109,41.417 368.655,40.829 Q369.201,40.241 370.146,40.241 Q371.091,40.241 371.637,40.829 Q372.183,41.417 372.183,42.299 Q372.183,43.181 371.637,43.769 Q371.091,44.357 370.146,44.357 Q369.201,44.357 368.655,43.769 Q368.109,43.181 368.109,42.299 z" />
        <path d="M373.653,30.959 Q373.653,30.161 374.188,29.615 Q374.724,29.069 375.606,29.069 Q376.488,29.069 377.023,29.615 Q377.559,30.161 377.559,30.959 Q377.559,31.757 377.023,32.303 Q376.488,32.849 375.606,32.849 Q374.724,32.849 374.188,32.303 Q373.653,31.757 373.653,30.959 z M377.391,43.979 Q377.16,44.021 376.708,44.095 Q376.257,44.168 375.795,44.168 Q375.333,44.168 374.965,44.105 Q374.598,44.042 374.346,43.853 Q374.094,43.664 373.957,43.339 Q373.821,43.013 373.821,42.488 L373.821,33.941 Q374.052,33.899 374.503,33.826 Q374.955,33.752 375.417,33.752 Q375.879,33.752 376.246,33.815 Q376.614,33.878 376.866,34.067 Q377.118,34.256 377.254,34.582 Q377.391,34.907 377.391,35.432 z" />
        <path d="M390.222,38.897 Q390.222,40.199 389.823,41.217 Q389.424,42.236 388.7,42.929 Q387.975,43.622 386.967,43.979 Q385.959,44.336 384.72,44.336 Q383.481,44.336 382.473,43.958 Q381.465,43.58 380.741,42.876 Q380.016,42.173 379.617,41.165 Q379.218,40.157 379.218,38.897 Q379.218,37.658 379.617,36.65 Q380.016,35.642 380.741,34.938 Q381.465,34.235 382.473,33.857 Q383.481,33.479 384.72,33.479 Q385.959,33.479 386.967,33.868 Q387.975,34.256 388.7,34.959 Q389.424,35.663 389.823,36.671 Q390.222,37.679 390.222,38.897 z M382.872,38.897 Q382.872,40.178 383.366,40.861 Q383.859,41.543 384.741,41.543 Q385.623,41.543 386.096,40.85 Q386.568,40.157 386.568,38.897 Q386.568,37.637 386.085,36.954 Q385.602,36.272 384.72,36.272 Q383.838,36.272 383.355,36.954 Q382.872,37.637 382.872,38.897 z" />
      </g>
    </svg>
  )
}

Logo.propTypes = {
  theme: PropTypes.oneOf(['current', 'blue', 'white-on-blue', 'dark-blue', 'white', 'light-gray']),
  size: PropTypes.number,
}

export default Logo
