import React from "react";

const ShoppingCart = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M18 2c-.466 0-.869.322-.975.775L16.051 7H3a1.005 1.005 0 0 0-.971 1.243l2 8c.112.445.512.757.971.757h10c.466 0 .869-.321.975-.775L18.795 4H22V2h-4zm-3.795 13H5.781l-1.5-6h11.308l-1.384 6z" />
    <circle cx={6} cy={20} r={2} />
    <circle cx={14} cy={20} r={2} />
  </svg>
);

export default ShoppingCart;
