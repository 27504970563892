import React from "react";

const Test = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M18 22H4c-1.103 0-2-.897-2-2V6c0-1.102.897-2 2-2h7v2H4v14h14v-9h2v9c0 1.103-.897 2-2 2z" />
    <path d="M9.808 10.365l2.828 2.827-3.535.707zM20.414 5.414a2 2 0 1 0-2.828-2.828L11.222 8.95l2.829 2.829 6.363-6.365z" />
  </svg>
);

export default Test;
