import React from "react";

const AlertCircle = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 24C5.383 24 0 18.618 0 12 0 5.383 5.383 0 12 0s12 5.383 12 12c0 6.618-5.383 12-12 12zm0-21.6c-5.293 0-9.6 4.307-9.6 9.6 0 5.293 4.307 9.6 9.6 9.6 5.293 0 9.6-4.307 9.6-9.6 0-5.293-4.307-9.6-9.6-9.6z" />
    <path d="M10.8 4.8h2.4v8.4h-2.4zM13.5 16.8a1.5 1.5 0 1 1-3.001-.001 1.5 1.5 0 0 1 3.001.001z" />
  </svg>
);

export default AlertCircle;
