import React from "react";

const QuestionCircleSolid = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 0C5.383 0 0 5.385 0 12c0 6.619 5.383 12.001 12 12.001S24 18.619 24 12c0-6.615-5.383-12-12-12zm0 19.501a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1.2-5.25v1.35h-2.4V12H12c1.325 0 2.4-1.076 2.4-2.4 0-1.324-1.075-2.4-2.4-2.4a2.401 2.401 0 0 0-2.4 2.4H7.2c0-2.646 2.154-4.8 4.8-4.8s4.8 2.154 4.8 4.8a4.812 4.812 0 0 1-3.6 4.651z" />
  </svg>
);

export default QuestionCircleSolid;
