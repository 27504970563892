import React from 'react'
import { css } from '@emotion/core'
import theme from './styles/theme'

const centeredStyles = css`
  padding-left: ${theme.gutter};
  padding-right: ${theme.gutter};

  > * {
    max-width: 1100px;
    margin: 0 auto;
  }
`

function Centered({ as: Component = 'div', children, ...rest }) {
  return (
    <div css={centeredStyles}>
      <Component {...rest}>{children}</Component>
    </div>
  )
}

export default Centered
