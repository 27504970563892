// import sharedTheme from '../shared/styles/theme.scss'
// import websiteTheme from './theme.scss'

// const theme = {
//   ...sharedTheme,
//   ...websiteTheme,
// }

// export default theme

const colors = {
  white: '#fff',
  black: '#000',
  lightGray: '#f6f9fc',

  // Pink
  pink: '#c7156e',
  darkPink: '#770e43',
  lightPink: '#ffeaf5',

  // Blue
  blue: '#2b92da',
  darkBlue: '#205e8e',
  darkDarkBlue: '#30556f',
  lightBlue: '#e4f6fc',
  brightBlue: '#2dc1ea',

  // Green
  green: '#6dcd86',
  darkGreen: '#498c5a',
  lightGreen: '#eff7f1',
  brightGreen: '#dbffe4',
}

const sharedTheme = {
  ...colors,

  // Semantic Colors
  warn: colors.pink,
  action: colors.green,
  success: colors.green,

  // Specific Colors
  lineColorLight: '#eaf2f7',
  lineColor: '#d6e5ef',
  lineColorActive: colors.brightBlue,

  // Text
  fontFamily: "'Fira Sans', Arial",
  titleFontFamily: "'Fira Sans', Arial",
  fontSize: '12pt',
  fontWeight: '400',
  textColor: '#444',
  textColorLight: '#888',
  disabledTextColor: '#aaa',
  anchorColor: colors.blue,

  // Layout
  gutter: '1rem',
  gutterSmall: '0.5rem',
  gutterLarge: '2rem',
}

const theme = {
  ...sharedTheme,

  // Colors
  anchorColor: colors.brightBlue,
  fontSize: '14pt',
  fontFamily: "'Lato', Arial'",
  titleFontFamily: "'Baloo Chettan', Arial'",
  scriptFontFamily: "'Courgette', Cursive",

  // Layout
  panelBreakpoint: '1050px',
}

export default theme
