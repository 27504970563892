import React from "react";

const Calendar = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      className="cls-1"
      d="M22 24H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h2v2H2v18h20V4h-2V2h2a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2zm-5-7h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zm0-4h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zm2-2h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zm-1-5a1 1 0 0 1-1-1V1a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1zm-4-4h2v2h-2V2zm-3 15h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zm0-4h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 0-2zm2-2h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zm-1-5a1 1 0 0 1-1-1V1a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1zM8 2h2v2H8V2zM5 17h2a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm0-4h2a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2zm2-2H5a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM6 6a1 1 0 0 1-1-1V1a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1z"
    />
  </svg>
);

export default Calendar;
